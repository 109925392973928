export const InitialState = {
    windowScrollTop: 0,
    hamburgerMenu: false,
    isMobile: false,
    isModalOpen: false,
    error: {
        origin: null,
        message: null,
    },
};

export const actionTypes = {
    SET_HAMBURGER_MENU: "SET_HAMBURGER_MENU",
    SET_IS_MOBILE: "SET_IS_MOBILE",
    SET_IS_MODAL_OPEN: "SET_IS_MODAL_OPEN",
    SET_WINDOW_SCROLLTOP: "SET_WINDOW_SCROLLTOP",
    SET_ERROR: "SET_ERROR",
};

export const Reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_HAMBURGER_MENU:
            return {
                ...state,
                hamburgerMenu: action.payload,
            };
        case actionTypes.SET_IS_MODAL_OPEN:
            return {
                ...state,
                isModalOpen: action.payload,
            };
        case actionTypes.SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload,
            };
        case actionTypes.SET_WINDOW_SCROLLTOP:
            return {
                ...state,
                windowScrollTop: action.payload,
            };

        case actionTypes.SET_ERROR:
            return {
                ...state,
                error: {
                    origin: action.payload.origin,
                    message: action.payload.message,
                },
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
